import { useEffect, useState } from "react";

import UsbCameraMonitoringView from ".";

function UsbCameraMonitoringWrapperView({ token, setToken }) {

    const [searchParams] = useState(new URLSearchParams(window.location.search));
    const urlParams = new URLSearchParams(window.location.search);

    const flipVertically = searchParams.get('flipVertically') === 'true';
    const controlsEnable = searchParams.get('controlsEnable') === 'true';
    let shouldTransmitAudio = urlParams.get("should_transmit_audio") === 'true';
    let shouldReceiveAudio = urlParams.get("should_receive_audio") === 'true';

    const [localMicrophoneEnabled, setLocalMicrophoneEnabled] = useState(shouldTransmitAudio);
    const [localSpeakerEnabled, setLocalSpeakerEnabled] = useState(shouldReceiveAudio);

    useEffect(() => {
        window.addEventListener("message", ({ data }) => {
            console.log("Message received from parent iframe", data);
            const messageData = JSON.parse(data);
            console.log("Message data", messageData);
            console.log("Message data type", messageData.type);
            console.log("Message data muteValue", messageData.muteValue);

            if(messageData.type === "isPatientAudioMuted") {
                setLocalSpeakerEnabled(!messageData.muteValue);
            } else if(messageData.type === "isLocalAudioMuted") {
                setLocalMicrophoneEnabled(!messageData.muteValue);
            } else if(messageData.type === "ping") {
              console.log("Got ping from parent iframe, sending pong");
              window.parent.postMessage(JSON.stringify({ type: "pong" }), "*");
            }
          });    
    }, []);

    return (
        <UsbCameraMonitoringView 
            flipVertically={flipVertically}
            controlsEnable={controlsEnable}
            shouldTransmitAudio={shouldTransmitAudio}
            shouldReceiveAudio={shouldReceiveAudio}
            localMicrophoneEnabled={localMicrophoneEnabled}
            setLocalMicrophoneEnabled={setLocalMicrophoneEnabled}
            localSpeakerEnabled={localSpeakerEnabled}
            setLocalSpeakerEnabled={setLocalSpeakerEnabled}
            token={token}
            setToken={setToken}
        />
    );
}

export default UsbCameraMonitoringWrapperView;