import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import ErrorPopUp from "../../Components/ErrorPopUp";
import API_ENDPOINTS from "../../API/endpoints";
import styles from "./style.module.scss";
import * as jose from 'jose'
import { TOOLTIP_POSITION } from "../../Components/useToolTip/constants";
import { ToolTipWrapper } from "../../Components/useToolTip";

var mySid = null;

var socketEndpoint = API_ENDPOINTS.SOCKET_URL;
console.log("socketEndpoint", socketEndpoint);

var socket = io(socketEndpoint, {
  transports: ["websocket"],
});

var socketInitialized = false;
var peerList = {};
let localStream = null;
let selectedSpeakerGlobal = '';

let participantStreams = new Map();

function UsbCameraMonitoringView({
  flipVertically,
  controlsEnable,
  shouldTransmitAudio,
  shouldReceiveAudio,
  localMicrophoneEnabled,
  setLocalMicrophoneEnabled,
  localSpeakerEnabled,
  setLocalSpeakerEnabled, 
  token, 
  setToken 
}) {

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [isWatching, setIsWatching] = useState(true);

  const [monitoringRoomTokenDict, setMonitoringRoomTokenDict] = useState({});

  const [hasMonitoringEnded, setHasMonitoringEnded] = useState(false);
  const searchParams = new URLSearchParams(document.location.search);
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);

  const [devices, setDevices] = useState({ audio: [], video: [], speaker: [] });

  const [selectedAudio, setSelectedAudio] = useState("");
  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedSpeaker, setSelectedSpeaker] = useState('');

  const defaultVideoLabel = 'md330ui';
  const defaultAudioLabel = 'md330ui';
  const defaultSpeakerLabel = 'md330ui';

  var _hospitalId = searchParams.get("hospitalId");
  var _usbCameraDeviceId = searchParams.get("usbCameraDeviceId");
  var _usbMonitoringRoomId = searchParams.get("usbMonitoringRoomId");
  var _isMonitoringUser = searchParams.get("isMonitoringUser");
  var _cameraDeviceSecret = searchParams.get("cameraDeviceSecret");

  function addParticipantStream(peerId, stream) {
    participantStreams.set(peerId, stream);
  }
  function removeParticipantStream(peerId) {
    participantStreams.delete(peerId);
  }

  async function getJwtTokenForDevice() {
    const jwtTokenData = {
        "hospital_id": _hospitalId,
        "usb_camera_device_id": _usbCameraDeviceId,
    }

    if (!_cameraDeviceSecret) {
      throw new Error("Camera device secret is required");
    }

    const secret = new TextEncoder().encode(
      _cameraDeviceSecret
    )
    const alg = 'HS256'
    
    const jwtToken = await new jose.SignJWT(jwtTokenData)
        .setProtectedHeader({ alg })
        .setIssuedAt()
        .setExpirationTime('2h')
        .sign(secret)
      
    console.log("jwtToken", jwtToken);
    return jwtToken;
  }

  function toggleMuteForMe() {
    setLocalSpeakerEnabled(!localSpeakerEnabled);
    setShowMoreOptions(false);
  }

  function toggleMuteMe() {
    setLocalMicrophoneEnabled(!localMicrophoneEnabled);
    setShowMoreOptions(false);
  }

  function toggleStopStartWatching() {
    setIsWatching(!isWatching);
    setShowMoreOptions(false);
  }

  function handleMoreOptionsClick() {
    setShowMoreOptions(!showMoreOptions);
  }  
  
  function handlePanTiltClick(relativeX, relativeY) {
    // todo: handle pan tilt
  }

  function handleZoomButtonClicked(zoomRelativeAmount) {
    // todo: handle zoom
  }

  function videoMonitoringEndedSelf() {

    Object.values(peerList).forEach((peerConnection) => {
      if (peerConnection) {
        console.log("Closing peer connection.");
        peerConnection.close();
      }
    });
    peerList = {};
    if (localStream) {
      localStream.getTracks().forEach((track) => {
        track.stop();
      });
      localStream = null;
    }
    if (socket.connected) {
      socket.disconnect();
    }
    setHasMonitoringEnded(true);
  }

  const openErrorPopup = () => {
    setIsErrorPopupOpen(true);
  };

  const closePopup = () => {
    setIsErrorPopupOpen(false);
  };
  function isChrome() {
    return /chrome|chromium|crios/i.test(navigator.userAgent);
  }

  var PC_CONFIG = {
    iceServers: [
      isChrome()
        ? { url: "stun:dev-stun.lana.health:3478" }
        : { urls: "stun:dev-stun.lana.health:3478" },
      {
        urls: "turn:dev-turn.lana.health:3478",
        username: "lana-turn-server",
        credential: "somepassword",
      },
    ],
  };

  function sendViaServer(data) {
    console.log("Sending via server: ", JSON.stringify(data));
    socket.emit("data", JSON.stringify(data));
  }

  const setDefaultAVDevices = async () => {
    try {
        await navigator.mediaDevices.getUserMedia({video: true, audio: true})
        const deviceInfos = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = deviceInfos.filter(device => device.kind === 'audioinput');
        const videoDevices = deviceInfos.filter(device => device.kind === 'videoinput');
        const speakerDevices = deviceInfos.filter(device => device.kind === 'audiooutput');

        let _selectedAudio = '';
        let _selectedVideo = '';
        let _selectedSpeaker = '';

        setDevices({ audio: audioDevices, video: videoDevices, speaker: speakerDevices });

        const defaultVideoDevice = videoDevices.find(device => device.label.toLowerCase().includes(defaultVideoLabel.toLowerCase()));

        if (defaultVideoDevice) {
            setSelectedVideo(defaultVideoDevice.deviceId);
            _selectedVideo = defaultVideoDevice.deviceId;
        } else if (videoDevices.length > 0) {
            setSelectedVideo(videoDevices[0].deviceId);
            _selectedVideo = videoDevices[0].deviceId;
        }

        const defaultAudioDevice = audioDevices.find(device => device.label.toLowerCase().includes(defaultAudioLabel.toLowerCase()));

        if (defaultAudioDevice) {
            setSelectedAudio(defaultAudioDevice.deviceId);
            _selectedAudio = defaultAudioDevice.deviceId;
        } else if (audioDevices.length > 0) {
            setSelectedAudio(audioDevices[0].deviceId);
            _selectedAudio = audioDevices[0].deviceId;
        }

        const defaultSpeakerDevice = speakerDevices.find(device => device.label.toLowerCase().includes(defaultSpeakerLabel.toLowerCase()));

        if (defaultSpeakerDevice) {
            setSelectedSpeaker(defaultSpeakerDevice.deviceId);
            _selectedSpeaker = defaultSpeakerDevice.deviceId;
        } else if (speakerDevices.length > 0) {
            setSelectedSpeaker(speakerDevices[0].deviceId);
            _selectedSpeaker = speakerDevices[0].deviceId;
        }

        await navigator.mediaDevices.getUserMedia({
          video: { deviceId: _selectedVideo ? { exact: _selectedVideo } : undefined },
          audio: { deviceId: _selectedAudio ? { exact: _selectedAudio } : undefined }
        }).then((stream) => {
          localStream = stream;
        })
        .catch((err) => {
          console.error("error:", err);
        });
    } catch (err) {
        console.error('Error accessing media devices.', err);
    }
  };

  const setSpeaker = () => {
    console.log("Selected speaker: ", selectedSpeaker);
    if (selectedSpeaker) {
      const videoElements = document.getElementsByTagName('video');

      for(let videoElement of videoElements) {
        videoElement.setSinkId(selectedSpeaker)
          .then(() => {
            console.log(`Audio output set to ${selectedSpeaker}`);
          })
          .catch((error) => {
            console.error(`Failed to set audio output: ${error}`);
          });
      }
    } else {
      console.error("selectedSpeaker is not set");
    }
  };

  useEffect(() => {

    if(!_isMonitoringUser) {
      setDefaultAVDevices();
    } else {
      // TODO: Need to get audio permission, else webrtc is not receiving track. Fix later
      // TODO: This should be conditional - should be called only if transmit audio is true
      navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true
      }).then((stream) => {
        localStream = stream;
      })
      .catch((err) => {
        console.error("error:", err);
      });
    }

    if (socketInitialized) {
      return;
    }
    socketInitialized = true;

    console.log("socket connected....");

    socket.on("connect", () => {
        if(_isMonitoringUser) {
            var data = {
                hospitalId: _hospitalId,
                usbCameraDeviceId: _usbCameraDeviceId,
                usbMonitoringRoomId: _usbMonitoringRoomId,
                isMonitoringUser: true,
                jwtToken: token,
            };

            if (token) {
                console.log("emitting join-usb-monitoring-room");
                socket.emit("join-usb-monitoring-room", data);
            } else {
                console.log("token is empty");
            }
        } else {
            getJwtTokenForDevice()
                .then((jwtToken) => {
                    var data = {
                        hospitalId: _hospitalId,
                        usbCameraDeviceId: _usbCameraDeviceId,
                        jwtToken: jwtToken
                    };
                    
                    socket.emit("join-usb-camera-room", data);
                    console.log("emitting join-usb-camera-room");
                });
        }
    });

    socket.on("join-usb-camera-room-response", (data) => {
      console.log("join-usb-camera-room-response", data);
    });

    socket.on("request-join-usb-monitoring-room", (data) => {
        console.log("request-join-usb-monitoring-room", data);
        
        if (data["jwtToken"] && data["usbMonitoringRoomId"]) {

            setMonitoringRoomTokenDict((prevDict) => ({
                ...prevDict,
                [data["usbMonitoringRoomId"]]: data["jwtToken"],
            }));

            var usbMonitoringRoomData = {
                hospitalId: _hospitalId,
                usbCameraDeviceId: _usbCameraDeviceId,
                usbMonitoringRoomId: data["usbMonitoringRoomId"],
                isMonitoringUser: false,
                jwtToken: data["jwtToken"],
            };
            console.log("emitting join-usb-monitoring-room");
            socket.emit("join-usb-monitoring-room", usbMonitoringRoomData);
        }
    });

    socket.on("usb-monitoring-user-connect", (data) => {
      console.log("usb-monitoring-user-connect ", data);

      let peerId = data["sid"];
      peerList[peerId] = undefined; // add new user to user list

      console.log("Updated peer list", peerList);
      console.log("peerList: ", peerList);
      addDiv(peerId);
    });

    socket.on("user-disconnect", (data) => {
      console.log("user-disconnect ", data);
      let peerId = data["sid"];
      if (peerId === mySid) {
        videoMonitoringEndedSelf();
        setToken("");
      } else {
        closeConnection(peerId);
        removeParticipantStream(peerId);
        console.log("Participant Stremas Map", participantStreams);
        removeVideoElement(peerId);
      }
    });

    socket.on("usb-monitoring-user-list", (data) => {
      console.log("user list recvd ", data);
      let _mySid = data["my_sid"];
      mySid = _mySid;

      if ("usersInRoom" in data && "usersInRoom" in data["usersInRoom"]) {
        // not the first to connect to room, existing user list recieved
        let recvd_list = data["usersInRoom"]["usersInRoom"];
        var otherUsersFound = false;
        // add existing users to user list
        for (let peerId of recvd_list) {
          console.log("Comparing " + peerId + " " + _mySid);
          if (peerId !== _mySid) {
            console.log("Adding peerId to peerList", peerId);
            peerList[peerId] = undefined;
            otherUsersFound = true;
          }
          // todo: add video frame
        }
        console.log("Updated peer list2", peerList);
        if (otherUsersFound) {
          console.log("otherUsersFound = true for peerList: ", peerList);
        }
        start_webrtc();
      }
    });

    socket.on("data", (msg) => {
      console.log("socket.on(data) : ", msg);
      switch (msg.type) {
        case "SessionDescription":
          switch (msg.payload.type) {
            case "offer":
              console.log("handelling offer");
              handleOfferMsg(msg);
              break;
            case "answer":
              console.log("handelling answer");
              handleAnswerMsg(msg);
              break;
          }
          break;
        case "IceCandidate":
          console.log("handelling IceCandidate");
          handleNewICECandidateMsg(msg);
          break;
      }
    });
  }, []);

  useEffect(() => {
    function switchMediaTracks() {
      const currentStream = localStream;

      if (!currentStream) return;

      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: selectedAudio ? { exact: selectedAudio } : undefined,
          },
          video: {
                deviceId: selectedVideo ? { exact: selectedVideo } : undefined,
                width: 1080,
                height: 720,
            }
        })
        .then((newStream) => {
          const oldAudioTrack = currentStream.getAudioTracks()[0];
          const newAudioTrack = newStream.getAudioTracks()[0];
          if (oldAudioTrack && newAudioTrack) {
            Object.values(peerList).forEach((pc) => {
              pc.getSenders().forEach((sender) => {
                if (sender.track.kind === "audio") {
                  sender.replaceTrack(newAudioTrack);
                }
              });
            });

            oldAudioTrack.stop();
          }
            const oldVideoTrack = currentStream.getVideoTracks()[0];
            const newVideoTrack = newStream.getVideoTracks()[0];
            if (oldVideoTrack && newVideoTrack) {
                Object.values(peerList).forEach((pc) => {
                pc.getSenders().forEach((sender) => {
                    if (sender.track.kind === "video") {
                    sender.replaceTrack(newVideoTrack);
                    }
                });
                });
            }
            oldVideoTrack.stop();
        })
        .catch((e) => console.error("Failed to switch media device: ", e));
    }
    switchMediaTracks();
  }, [selectedAudio, selectedVideo]);

  useEffect(() => {
    selectedSpeakerGlobal = selectedSpeaker;
    setSpeaker();
  }, [selectedSpeaker]);

  useEffect(() => {
    const videoElements = document.getElementsByTagName('video');
    for(let videoElement of videoElements) {
      if(shouldReceiveAudio && localSpeakerEnabled) {
        videoElement.volume = 1;
        videoElement.removeAttribute('muted');
      } else {
        videoElement.volume = 0;
      }
    }
  }, [localSpeakerEnabled]);

  useEffect(() => {
    if(!localStream) {
      console.log("Local stream is undefined, doing nothing");
      return;
    }

    console.log("Handling localMicrophoneEnabled", localMicrophoneEnabled);
    if (localMicrophoneEnabled) {
      localStream.getAudioTracks().forEach((track) => {
        track.enabled = true;
      });
    } else {
      localStream.getAudioTracks().forEach((track) => {
        track.enabled = false;
      });
    }
  }, [localMicrophoneEnabled]);

  function invite(peer_id) {
    if (peerList[peer_id]) {
      console.log(
        "[Not supposed to happen!] Attempting to start a connection that already exists!"
      );
    } else if (peer_id === mySid) {
      console.log("[Not supposed to happen!] Trying to connect to self!");
    } else {
      console.log(`Creating peer connection for <${peer_id}> ...`);
      createPeerConnection(peer_id);

      console.log(`Adding local_stream`);

      if (!_isMonitoringUser) {
        console.log("Not a monitoring user, adding local stream for peer_id", peer_id);
        localStream?.getTracks()?.forEach((track) => {
          console.log("Adding track for peer_id", peer_id);
          peerList[peer_id].addTrack(track, localStream);
        });  
      } else {
        localStream?.getAudioTracks()?.forEach((track) => {
          console.log("Adding audio track for peer_id", peer_id);
          peerList[peer_id].addTrack(track, localStream);
        });
        console.log("Monitoring user, not adding local stream for peer_id", peer_id);
      }
    }
  }

  function createPeerConnection(peer_id) {
    console.log("createPeerConnection");
    peerList[peer_id] = new RTCPeerConnection(PC_CONFIG);

    peerList[peer_id].onicecandidate = (event) => {
      handleICECandidateEvent(event, peer_id);
    };
    peerList[peer_id].ontrack = (event) => {
      handleTrackEvent(event, peer_id);
    };

    peerList[peer_id].onnegotiationneeded = () => {
      handleNegotiationNeededEvent(peer_id);
    };
  }

  function handleNegotiationNeededEvent(peer_id) {
    console.log("Peer connection state: ", peerList[peer_id].connectionState);

    peerList[peer_id]
      .createOffer()
      .then((offer) => {
        return peerList[peer_id].setLocalDescription(offer);
      })
      .then(() => {
        console.log(`sending offer to <${peer_id}> ...`);
        sendViaServer({
          type: "SessionDescription", //sending offer
          payload: {
            senderId: mySid,
            targetId: peer_id,
            sdp: peerList[peer_id].localDescription.sdp,
            type: peerList[peer_id].localDescription.type,
          },
        });
      })
      .catch((e) => console.log("[ERROR] ", e));
  }

  function handleOfferMsg(msg) {
    console.log("handleOfferMsg");
    let peer_id = msg["payload"]["senderId"];
    console.log("msg", msg);

    console.log(`offer recieved from <${peer_id}>`);

    createPeerConnection(peer_id);

    let desc = new RTCSessionDescription({
      type: msg.payload.type,
      sdp: msg.payload.sdp,
    });

    peerList[peer_id]
      .setRemoteDescription(desc)
      .then(() => {
        localStream?.getTracks()?.forEach((track) => {
          console.log("adding tracks...");
          peerList[peer_id].addTrack(track, localStream);
        });
      })
      .then(() => {
        console.log("creating answer...");
        return peerList[peer_id].createAnswer();
      })
      .then((answer) => {
        console.log("setting local description...");
        return peerList[peer_id].setLocalDescription(answer);
      })
      .then(() => {
        console.log(`sending answer to <${peer_id}> ...`);
        sendViaServer({
          type: "SessionDescription", //sending answer
          payload: {
            senderId: mySid,
            targetId: peer_id,
            sdp: peerList[peer_id].localDescription.sdp,
            type: peerList[peer_id].localDescription.type,
          },
        });
      })
      .catch((e) => console.log("[ERROR] ", e));
  }

  function handleAnswerMsg(msg) {
    console.log("handleAnswerMsg");
    let peer_id = msg["payload"]["senderId"];
    console.log(`answer recieved from <${peer_id}>`);
    let desc = new RTCSessionDescription({
      type: msg.payload.type,
      sdp: msg.payload.sdp,
    });

    peerList[peer_id].setRemoteDescription(desc);
  }

  function handleICECandidateEvent(event, peer_id) {
    console.log("handleICECancidate");
    if (event.candidate) {
      sendViaServer({
        type: "IceCandidate",
        payload: {
          senderId: mySid,
          targetId: peer_id,
          candidate: event.candidate.candidate,
          sdpMLineIndex: event.candidate.sdpMLineIndex,
          sdpMid: event.candidate.sdpMid,
        },
      });
    }
  }

  function handleNewICECandidateMsg(msg) {
    console.log("handleNewICECandidateMsg msg", msg);
    var candidate = new RTCIceCandidate({
      candidate: msg.payload.candidate,
      sdpMid: msg.payload.sdpMid,
      sdpMLineIndex: msg.payload.sdpMLineIndex,
    });
    peerList[msg["payload"]["senderId"]]
      .addIceCandidate(candidate)
      .catch((e) => console.log("[ERROR] ", e));
  }

  function closeConnection(peerId) {
    console.log("closeConnection");
    if (peerId in peerList) {
      if (peerList[peerId]) {
        peerList[peerId].close();
        peerList[peerId].onicecandidate = null;
        peerList[peerId].ontrack = null;
        peerList[peerId].onnegotiationneeded = null;
      }
      delete peerList[peerId];
    }
  }

  function handleTrackEvent(event, peer_id) {
    console.log(`track event recieved from <${peer_id}>`);

    console.log("event.streams", event.streams);
    if (event.streams) {
        // todo: Find a better way to do this and to do addDiv
        console.log("Calling addTrack");
        setTimeout(() => {
            if (_isMonitoringUser) {
                console.log("is monitoring user, calling addTrack for peer_id", peer_id);
                addTrack(event, peer_id);

                const participantStream = event.streams[0];

                addParticipantStream(peer_id, participantStream);
                console.log(participantStreams);
            } else {
              console.log("is not monitoring user, calling addTrack for peer_id for the audio stream", peer_id);
              addTrack(event, peer_id);
            }
        }, 1000);
    }
  }

  function start_webrtc() {
    console.log("peerList", peerList);
    console.log("mySid", mySid);
    // send offer to all other members
    for (let peerId in peerList) {
      if (peerId !== mySid) {
        invite(peerId);
        console.log("Calling invite() for " + peerId);
        addDiv(peerId);
      }
    }
  }

  function addDiv(peer_id) {
    let peerDivId = "div-id-" + peer_id;

    if (document.getElementById(peerDivId)) {
      return;
    }

    const videoDiv = document.createElement("div");
    videoDiv.classList.add(styles.videoDiv);
    videoDiv.id = peerDivId;
    videoDiv.style.width = "100%";
    videoDiv.style.height = "100%";
    videoDiv.style.borderRadius = "12px";
    videoDiv.style.overflow = "hidden";
    videoDiv.style.position = "relative";
    videoDiv.addEventListener("click", () => {
      console.log("Clicked on videoDiv");
    });

    document
      .getElementById("video-container-id")
      .appendChild(videoDiv);

    requestAnimationFrame(() => {
      videoDiv.classList.add(styles.videoDivVisible);
    });
  }

  function addTrack(event, peer_id) {
    console.log("Inside addTrack of UsbCameraMonitoringView");

    let videoElementId = "video-id-" + peer_id;
    let peerDivId = "div-id-" + peer_id;

    if (document.getElementById(videoElementId)) {
      console.log("videoElementId not found, returning");
      return;
    }

    if (!document.getElementById(peerDivId)) {
      console.log("peerDivId not found, returning");
      return;
    }

    const video = document.createElement("video");
    video.id = videoElementId;

    video.srcObject = event.streams[0];
    video.autoplay = true;
    video.style.width = "100%";
    video.style.height = "auto";
    video.style.borderRadius = "12px";
    video.style.transform = flipVertically ? "rotateY(180deg)" : "rotateY(0deg)";

    console.log("setting selectedSpeaker: ", selectedSpeakerGlobal);
    video.setSinkId(selectedSpeakerGlobal);

    document.getElementById(peerDivId).appendChild(video);
  }

  function removeVideoElement(peer_id) {
    let videoElementId = "video-id-" + peer_id;
    let peerDivId = "div-id-" + peer_id;

    let videoObject = document.getElementById(videoElementId);
    if (videoObject) {
      if (videoObject.srcObject) {
        videoObject.srcObject
          ?.getTracks()
          ?.forEach((track) => track.stop());
      }
      if (videoObject.hasAttribute("srcObject")) {
        videoObject.removeAttribute("srcObject");
      }
      if (videoObject.hasAttribute("src")) {
        videoObject.removeAttribute("src");
      }
      document.getElementById(videoElementId).remove();
    }
    document.getElementById(peerDivId).remove();

  }

  if (hasMonitoringEnded) {
    return <span>Monitoring ended</span>;
  } else {
    return (
      <div id="video-container-id" style={{height: '100vh', width: '100vw'}}>
        <ErrorPopUp isOpen={isErrorPopupOpen} handleClose={closePopup} />
        {!_isMonitoringUser &&
          <>
            <div>
              <label htmlFor="audioSource">Audio Source: </label>
              <select
                  id="audioSource"
                  value={selectedAudio}
                  onChange={(e) => setSelectedAudio(e.target.value)}
              >
                  {devices.audio.map((device) => (
                      <option key={device.deviceId} value={device.deviceId}>
                          {device.label || `Microphone ${device.deviceId}`}
                      </option>
                  ))}
              </select>
            </div>
            <div>
              <label htmlFor="videoSource">Video Source: </label>
              <select
                  id="videoSource"
                  value={selectedVideo}
                  onChange={(e) => setSelectedVideo(e.target.value)}
              >
                  {devices.video.map((device) => (
                      <option key={device.deviceId} value={device.deviceId}>
                          {device.label || `Camera ${device.deviceId}`}
                      </option>
                  ))}
              </select>
            </div>
            <div>
              <label htmlFor="speakerSource">Speaker Source:</label>
              <select
                  id="speakerSource"
                  value={selectedSpeaker}
                  onChange={(e) => setSelectedSpeaker(e.target.value)}
              >
                  {devices.speaker.map((device) => (
                      <option key={device.deviceId} value={device.deviceId}>
                          {device.label || `Speaker ${device.deviceId}`}
                      </option>
                  ))}
              </select>
            </div>
          </>
        }
        {_isMonitoringUser && controlsEnable && (
          <div className={styles.cameraControlsDiv}>
            <ToolTipWrapper
              infoText="Pan Left"
              tooltipPosition={TOOLTIP_POSITION.BOTTOM}
              style={{ transform: "translateX(-28px) translateY(-76px)" }}
            >

              <img 
                src="assets/icn-tilt-left.svg" 
                alt="Tilt Left" 
                onClick={() => handlePanTiltClick(-100, 0)}
              />

            </ToolTipWrapper>

            <ToolTipWrapper
              infoText="Pan Right"
              tooltipPosition={TOOLTIP_POSITION.BOTTOM}
              style={{ transform: "translateX(-28px) translateY(-76px)" }}
            >
              <img 
                src="assets/icn-tilt-right.svg" 
                alt="Tilt Right" 
                onClick={() => handlePanTiltClick(100, 0)}
              />
            </ToolTipWrapper>

            <ToolTipWrapper
              infoText="Pan Up"
              tooltipPosition={TOOLTIP_POSITION.BOTTOM}
              style={{ transform: "translateX(-28px) translateY(-76px)" }}
            >
              <img 
                src="assets/icn-tilt-up.svg" 
                alt="Tilt Up" 
                onClick={() => handlePanTiltClick(0, -100)}
              />
            </ToolTipWrapper>

            <ToolTipWrapper
              infoText="Pan Down"
              tooltipPosition={TOOLTIP_POSITION.BOTTOM}
              style={{ transform: "translateX(-30px) translateY(-76px)" }}
            >

              <img 
                src="assets/icn-tilt-down.svg" 
                alt="Tilt Down" 
                onClick={() => handlePanTiltClick(0, 100)}
              />
            </ToolTipWrapper>

            <div className={styles.verticalLine} />

            <ToolTipWrapper
              infoText="Zoom In"
              tooltipPosition={TOOLTIP_POSITION.BOTTOM}
              style={{ transform: "translateX(-25px) translateY(-76px)" }}
            >
              <img 
                src="assets/icn-zoom-in.svg" 
                alt="Zoom In" 
                onClick={() => handleZoomButtonClicked(100)}
              />
            </ToolTipWrapper>

            <ToolTipWrapper
              infoText="Zoom Out"
              tooltipPosition={TOOLTIP_POSITION.BOTTOM}
              style={{ transform: "translateX(-30px) translateY(-76px)" }}
            >
            <img 
              src="assets/icn-zoom-out.svg" 
              alt="Zoom Out" 
              onClick={() => handleZoomButtonClicked(-100)}
            />
            </ToolTipWrapper>

            <div className={styles.verticalLine} />

            <img 
              src="assets/icn-more-options.svg" 
              alt="More options" 
              style={{marginRight: 0}}
              onClick={() => handleMoreOptionsClick()}
              className="moreOptionsBtn"
            />

            {showMoreOptions && (
              <div className={styles.moreOptionsDropdown}>
                {shouldReceiveAudio && (
                  <div className={styles.dropdownItem} onClick={() => toggleMuteForMe()}>
                    <img src={localSpeakerEnabled ? "assets/icn-mic.svg" : "assets/icn-mic-muted.svg"} 
                      alt="Mute" 
                      style={{ filter: localSpeakerEnabled ? "brightness(0)" : "brightness(1)" }} />
                    {localSpeakerEnabled ? 'Mute for me' : 'Unmute for me'}
                  </div>
                )}
                <div className={styles.dropdownItem} onClick={() => toggleStopStartWatching()}>
                  <img src={isWatching ? "assets/icn-video.svg" : "assets/icn-video-off.svg"} 
                      alt="Stop" 
                      style={{ filter: isWatching ? "brightness(0)" : "brightness(1)" }} />
                  {isWatching ? 'Stop watching' : 'Start watching'}
                </div>
                {/* TODO: following code is temp, remove after testing */}
                {shouldTransmitAudio && (
                  <div className={styles.dropdownItem} onClick={() => toggleMuteMe()}>
                    <img src={localMicrophoneEnabled ? "assets/icn-mic.svg" : "assets/icn-mic-muted.svg"} 
                      alt="Mute" 
                      style={{ filter: localMicrophoneEnabled ? "brightness(0)" : "brightness(1)" }} />
                    {localMicrophoneEnabled ? 'Mute me' : 'Unmute me'}
                  </div>
                )}
              </div>            
            )}
          </div>
        )}
      </div>
    );
  }
}

export default UsbCameraMonitoringView;
