import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
} from "react";
import Button from "../../Components/Buttons";
import ControlButton from "../../Components/ControlButtons";
import IncomingVideo from "../../Components/IncomingVideo";
import LocalVideoStream from "../../Components/LocalVideoStream";
import styles from "./style.module.scss";
import Utilities from "../../Utils/utilities";
import Lobby from "../Lobby";
import Popup from "../../Components/Popup";
import VideoDiv from "../VideoDiv";
import {
  TOOLTIP_POSITION,
  ToolTipWrapper,
} from "@lana-health/lana-react-component-library";

const Chatroom = forwardRef(
  (
    {
      joinedPeerListCount,
      isLocalAudioMuted,
      askToJoinLobby,
      mutePeer,
      isAdmin,
      removePeer,
      peerSharingScreenId,
      setIsLocalAudioMuted,
      setAdmitAllowedLobbyMember,
      setDeclineDiscardedLobbyMember,
      showSelectDevicePopUp,
      setShowSelectDevicePopUp,
      participantStates,
      isLocalVideoMuted,
      setIsLocalVideoMuted,
      isPatientAudioMuted,
      isPatientVideoMuted,
      selectedAudio,
      selectedVideo,
      setSelectedAudio,
      setSelectedVideo,
      patientViewUrl,
      isRecording,
      isSomeOneElseRecording,
      startRecording,
      stopRecording,
      endCallButtonClicked,
      setTotalCallTime,
      isSharingScreen,
      toggleScreenShare,
    },
    ref
  ) => {
    const localVideoStreamRef = useRef();
    const [lobbyActive, setLobbyActive] = useState(false);
    const [startTime, setStartTime] = useState(new Date());
    const [elapsedTime, setElapsedTime] = useState({ minutes: 0, seconds: 0 });
    const [randomColor, setRandomColor] = useState(getRandomColor());
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [pinnedVideoPeerId, setPinnedVideoPeerId] = useState(null);
    const [pinnedVideoHeight, setPinnedVideoHeight] = useState(null);
    const [isCallRecordingPopupClosed, setIsCallRecordingPopupClosed] =
      useState(false);

    useEffect(() => {
      if (!isRecording) {
        setIsCallRecordingPopupClosed(false);
      }
    }, [isRecording]);
    const containerStyle = {
      alignContent: "center",
      justifyContent: pinnedVideoPeerId !== null ? "flex-start" : "center",
    };

    const handleOpenPopup = () => {
      setPopupOpen(true);
    };

    const handleClosePopup = () => {
      setPopupOpen(false);
    };

    useEffect(() => {
      console.log("lobbyActive", lobbyActive);
    }, [lobbyActive]);

    function getRandomColor() {
      const colors = ["#5D9EEE", "#4CC2C9", "#5D9EEE"];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    }

    useEffect(() => {
      setRandomColor(getRandomColor());
    }, []);
    useEffect(() => {
      rebalanceChildren();
    }, [joinedPeerListCount, lobbyActive]);

    useEffect(() => {
      const intervalId = setInterval(() => {
        const currentTime = new Date();
        const elapsedMilliseconds = currentTime - startTime;
        const elapsedMinutes = Math.floor(elapsedMilliseconds / (1000 * 60));
        const elapsedSeconds = Math.floor(
          (elapsedMilliseconds % (1000 * 60)) / 1000
        );
        setElapsedTime({ minutes: elapsedMinutes, seconds: elapsedSeconds });
      }, 1000);

      return () => clearInterval(intervalId);
    }, [startTime]);

    const formattedTime = `${String(elapsedTime.minutes).padStart(
      2,
      "0"
    )}:${String(elapsedTime.seconds).padStart(2, "0")}`;

    useImperativeHandle(ref, () => ({
      getLocalStream() {
        return localVideoStreamRef.current.getLocalStream();
      },
      updateScreenStream(screenStream) {
        localVideoStreamRef.current.updateScreenStream(screenStream);
      },
      updateVideoStream(localVideoStream) {
        localVideoStreamRef.current.updateVideoStream(localVideoStream);
      },
    }));

    const rebalanceChildren = () => {
      let videoGridContainer = document.getElementById(
        "video-grid-container-id"
      );
      let children = videoGridContainer.children;

      let boxesCount = children.length;
      let aspectRatio = 1.77777778;

      let maxWidth = Utilities.findMaxWidth(
        videoGridContainer.offsetHeight,
        videoGridContainer.offsetWidth,
        10,
        1 / aspectRatio,
        boxesCount
      );
      if (!lobbyActive && pinnedVideoPeerId) {
        setPinnedVideoHeight(
          (boxesCount * maxWidth) / aspectRatio + (boxesCount - 1) * 10 + "px"
        );
      }

      for (var i = 0; i < children.length; i++) {
        var divChild = children[i];
        divChild.style.maxWidth = maxWidth + "px";
        divChild.style.maxHeight = maxWidth / aspectRatio + "px";
      }
    };

    function pinVideo(id) {
      setPinnedVideoPeerId(id);
    }

    function removePinnedVideo(id) {
      if (id === pinnedVideoPeerId) {
        setPinnedVideoPeerId(null);
      }
    }

    function replacePinnedVideo(old_pinned_video_id, new_pinned_video_id) {
      if (old_pinned_video_id === pinnedVideoPeerId) {
        removePinnedVideo(old_pinned_video_id);
        setPinnedVideoPeerId(new_pinned_video_id);
      }
    }

    const videoGridContainerRef = useRef(null);

    let videoGridContainer = videoGridContainerRef.current;
    let children = videoGridContainer ? videoGridContainer.children : [];

    useEffect(() => {
      rebalanceChildren();
    }, [children.length]);

    useEffect(() => {
      rebalanceChildren();
    }, [pinnedVideoPeerId]);

    useEffect(() => {
      window.addEventListener("resize", rebalanceChildren);
      return () => {
        window.removeEventListener("resize", rebalanceChildren);
      };
    }, []);
    useEffect(() => {
      if (peerSharingScreenId && joinedPeerListCount > 1) {
        if (pinnedVideoPeerId) {
          replacePinnedVideo(pinnedVideoPeerId, peerSharingScreenId);
        } else {
          pinVideo(peerSharingScreenId);
        }
      }
    }, [peerSharingScreenId, joinedPeerListCount]);

    const memoizedLocalVideoStream = useMemo(
      () => (
        <LocalVideoStream
          randomColor={randomColor}
          isLocalAudioMuted={isLocalAudioMuted}
          isLocalVideoMuted={isLocalVideoMuted}
          showSelectDevicePopUp={showSelectDevicePopUp}
          setShowSelectDevicePopUp={setShowSelectDevicePopUp}
          selectedAudio={selectedAudio}
          selectedVideo={selectedVideo}
          setSelectedAudio={setSelectedAudio}
          setSelectedVideo={setSelectedVideo}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "12px",
            overflow: "hidden",
            position: "relative",
            display: joinedPeerListCount > 1 ? "block" : "none",
          }}
          updateVideoRef={(videoRef) => {
            console.log("Updating videoRefFromChild in Chatroom", videoRef);
          }}
          ref={localVideoStreamRef}
          isSharingScreen={isSharingScreen}
        />
      ),
      [
        isLocalAudioMuted,
        isLocalVideoMuted,
        isSharingScreen,
        joinedPeerListCount,
      ]
    );

    return (
      <>
        <div className={styles.callRoom}>
          <div className={styles.headerDivWrapper}>
            <div className={styles.headerDiv}>
              <div className={styles.callDetails}>
                <p className={styles.roomName}> Room 306-A</p>
                <div
                  style={{
                    height: "24px",
                    width: "1px",
                    backgroundColor: "#A6A6A6",
                  }}
                />
                <div
                  style={{ gap: "4px", display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                      backgroundColor: "#F95763",
                    }}
                  />
                  <p className={styles.timeText}>{formattedTime}</p>
                </div>
              </div>
              <div className={styles.imgContainer}>
                <img
                  className="hospital-logo"
                  src="/logo.png"
                  style={{ width: "64.4px", height: "24px" }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              maxHeight: "85vh",
            }}
          >
            <div
              className={styles.videoContainer}
              style={lobbyActive ? { flex: "75%" } : {}}
            >
              {pinnedVideoPeerId && joinedPeerListCount > 1 && (
                <div
                  id="pinned-video"
                  className={
                    pinnedVideoPeerId && joinedPeerListCount > 1
                      ? styles.pinnedVideoActiveDiv
                      : null
                  }
                  style={{ height: pinnedVideoHeight }}
                >
                  <VideoDiv
                    peer_id={pinnedVideoPeerId}
                    displayName={
                      participantStates[pinnedVideoPeerId].displayName
                    }
                    isPatient={participantStates[pinnedVideoPeerId].isPatient}
                    patientViewUrl={patientViewUrl}
                    isPatientVideoMuted={isPatientVideoMuted}
                    isPatientAudioMuted={isPatientAudioMuted}
                    peerVideoStream={
                      participantStates[pinnedVideoPeerId].participantStream
                    }
                    peerAudioMuted={
                      participantStates[pinnedVideoPeerId].audioMuted
                    }
                    peerVideoMuted={
                      participantStates[pinnedVideoPeerId].videoMuted
                    }
                    joinedPeerListCount={joinedPeerListCount}
                    rightButtonText={"Unpin"}
                    rightButtonHandler={() => {
                      removePinnedVideo(pinnedVideoPeerId);
                    }}
                    rebalanceChildren={rebalanceChildren}
                    randomColor={randomColor}
                  />
                </div>
              )}
              <div className={styles.videoGridContainerWrapper}>
                <div
                  ref={videoGridContainerRef}
                  id="video-grid-container-id"
                  className={styles.videoGridContainer}
                  style={{
                    flex:
                      pinnedVideoPeerId && joinedPeerListCount > 1
                        ? "1"
                        : "0 1 auto",
                    backgroundColor:
                      joinedPeerListCount > 0 ? "transparent" : undefined,
                    ...containerStyle,
                  }}
                >
                  {joinedPeerListCount > 0 ? (
                    Object.keys(participantStates)
                      .filter((peerId) =>
                        pinnedVideoPeerId ? peerId !== pinnedVideoPeerId : true
                      )
                      .map((peerId) => (
                        <VideoDiv
                          key={peerId}
                          peer_id={peerId}
                          displayName={participantStates[peerId].displayName}
                          isPatient={participantStates[peerId].isPatient}
                          patientViewUrl={patientViewUrl}
                          isPatientVideoMuted={isPatientVideoMuted}
                          isPatientAudioMuted={isPatientAudioMuted}
                          isScreenBeingShared={
                            participantStates[peerId].isScreenBeingShared
                          }
                          peerVideoStream={
                            participantStates[peerId].participantStream
                          }
                          peerAudioMuted={participantStates[peerId].audioMuted}
                          peerVideoMuted={participantStates[peerId].videoMuted}
                          joinedPeerListCount={joinedPeerListCount}
                          rightButtonText={"Pin"}
                          rightButtonHandler={
                            pinnedVideoPeerId
                              ? () =>
                                  replacePinnedVideo(pinnedVideoPeerId, peerId)
                              : () => pinVideo(peerId)
                          }
                          rebalanceChildren={rebalanceChildren}
                          randomColor={randomColor}
                        />
                      ))
                  ) : (
                    <div
                      id="video-grid-none-text"
                      style={{
                        position: "absolute",
                        top: "50%",
                        color: "white",
                        fontSize: 20,
                        transform: "translateY(-50%)",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "24px",
                        }}
                      >
                        <img
                          src="/assets/loader.svg"
                          className={styles.loaderImage}
                          height={48}
                          width={48}
                        />
                        <p className={styles.waitingText}>
                          Waiting for others to join
                        </p>
                      </div>
                    </div>
                  )}

                  {joinedPeerListCount > 1 && memoizedLocalVideoStream}
                </div>
                {/* <IncomingVideo /> */}

                <LocalVideoStream
                  isLocalAudioMuted={isLocalAudioMuted}
                  randomColor={randomColor}
                  isLocalVideoMuted={isLocalVideoMuted}
                  showSelectDevicePopUp={showSelectDevicePopUp}
                  setShowSelectDevicePopUp={setShowSelectDevicePopUp}
                  selectedAudio={selectedAudio}
                  selectedVideo={selectedVideo}
                  setSelectedAudio={setSelectedAudio}
                  setSelectedVideo={setSelectedVideo}
                  style={
                    joinedPeerListCount <= 1
                      ? {
                          position: "absolute",
                          width: "17%",
                          right: "24px",
                          bottom: "24px",
                          borderRadius: "8px",
                          border: "6px solid #2C2C2E",
                          backgroundColor: "#2C2C2E",
                          zIndex: 3000,
                          display: "block",
                          display: "flex",
                          justifyContent: "center",
                        }
                      : {
                          display: "none",
                        }
                  }
                  updateVideoRef={(videoRef) => {
                    console.log(
                      "Updating videoRefFromChild in Chatroom",
                      videoRef
                    );
                  }}
                  ref={localVideoStreamRef}
                  isSharingScreen={isSharingScreen}
                />
              </div>
            </div>

            <Lobby
              participantStates={participantStates}
              mutePeer={mutePeer}
              removePeer={removePeer}
              isAdmin={isAdmin}
              setAdmitAllowedLobbyMember={setAdmitAllowedLobbyMember}
              setDeclineDiscardedLobbyMember={setDeclineDiscardedLobbyMember}
              askToJoinLobby={askToJoinLobby}
              isLocalAudioMuted={isLocalAudioMuted}
              isLocalVideoMuted={isLocalVideoMuted}
              handleOpenPopup={handleOpenPopup}
              lobbyActive={lobbyActive}
              setLobbyActive={(active) => setLobbyActive(active)}
              randomColor={randomColor}
            />
          </div>

          <div className={styles.footerContainer}>
            {/* <div style={{ width: 1 }}></div> */}
            <div>
              {isRecording && !isCallRecordingPopupClosed && (
                <div
                  className={styles.callRecordingPopUpContainer}
                >
                  <p
                   className={styles.callRecordingPopUpText}
                  >
                    Call is being recorded
                  </p>

                  <Button.IconButton
                    icon={"/assets/icn-cancel.svg"}
                    style={{ backgroundColor: "transparent" }}
                    onClickHandler={() => {
                      setIsCallRecordingPopupClosed(true);
                    }}
                  />
                </div>
              )}
              <ToolTipWrapper
                infoText={isRecording ? "Stop Recording" : "Record Audio"}
                tooltipPosition={TOOLTIP_POSITION.BOTTOM}
                style={{
                  transform: "translateX(-20px) translateY(-90px)",
                  width: "max-content",
                  height: "max-content",
                }}
              >
                <Button.IconButton
                  icon={"/assets/icn-record-audio.svg"}
                  style={
                    isRecording
                      ? {
                          backgroundColor: "#F94D59",
                        }
                      : isSomeOneElseRecording
                      ? {
                          opacity: "0.2",
                          cursor: "not-allowed",
                          pointerEvents: "none",
                        }
                      : { backgroundColor: "#5F5F63" }
                  }
                  onClickHandler={isRecording ? stopRecording : startRecording}
                />
              </ToolTipWrapper>
            </div>
            <ControlButton
              joinedPeerListCount={joinedPeerListCount}
              setAdmitAllowedLobbyMember={setAdmitAllowedLobbyMember}
              peerSharingScreenId={peerSharingScreenId}
              askToJoinLobby={askToJoinLobby}
              isSomeOneElseRecording={isSomeOneElseRecording}
              withEndCallBttn={true}
              setShowSelectDevicePopUp={setShowSelectDevicePopUp}
              withScreenShareButton={true}
              withLobby={true}
              withElipsis={true}
              isRecording={isRecording}
              startRecording={startRecording}
              stopRecording={stopRecording}
              isLocalAudioMuted={isLocalAudioMuted}
              isLocalVideoMuted={isLocalVideoMuted}
              setIsLocalAudioMuted={setIsLocalAudioMuted}
              setIsLocalVideoMuted={setIsLocalVideoMuted}
              endCallButtonClicked={endCallButtonClicked}
              lobbyActive={lobbyActive}
              setLobbyActive={setLobbyActive}
              isSharingScreen={isSharingScreen}
              toggleScreenShare={toggleScreenShare}
              controlButtonsPaddingLeftStyle={126}
            />

            <Button.IconButton
              text={"Leave Call"}
              style={{
                backgroundColor: "#F94D59",
                color: "#FFFFFF",
                padding: "16px 16px",
                alignSelf: "flex-end",
              }}
              onClickHandler={() => {
                if (endCallButtonClicked) {
                  endCallButtonClicked();
                  setTotalCallTime(formattedTime);
                }
              }}
            />
          </div>
        </div>
        <Popup isOpen={isPopupOpen} onClose={handleClosePopup} />
      </>
    );
  }
);

export default Chatroom;
