import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./style.module.scss";
import Utilities from "../../Utils/utilities";

const VideoDiv = ({
  peer_id,
  displayName,
  isPatient,
  patientViewUrl,
  isPatientVideoMuted,
  isPatientAudioMuted,
  isLocalAudioMuted,
  peerVideoStream,
  peerAudioMuted,
  isScreenBeingShared,
  peerVideoMuted,
  joinedPeerListCount,
  rebalanceChildren,
  rightButtonText,
  rightButtonHandler,
  randomColor,
}) => {
  const videoRef = useRef(null);
  const iframeRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const passDataToPatientViewIframe = (dataToPass) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        JSON.stringify(dataToPass),
        "*"
      );
    }
  };
  function passInitialDataToPatientViewIframe() {
    // Check if iframe is ready by sending ping messages
    let pingAttempts = 0;
    const maxPingAttempts = 25;
    const pingInterval = 500;
    let pingIntervalId;

    // Handle messages from iframe
    const messageHandler = (event) => {
      console.log("Received event from iframe:", event);
      console.log("Received data from iframe:", event.data);
      if (event.data) {
        try {
          const data = JSON.parse(event.data);
          console.log("Parsed data from iframe:", data);
          if (data.type === "pong") {
            console.log("Received pong message from iframe");
            clearInterval(pingIntervalId);
            window.removeEventListener("message", messageHandler);
            iframeInitialized();
          }
        } catch (e) {
          console.error("Error parsing message:", e);
        }
      }
    };

    window.addEventListener("message", messageHandler);

    pingIntervalId = setInterval(() => {
      if (pingAttempts >= maxPingAttempts) {
        clearInterval(pingIntervalId);
        window.removeEventListener("message", messageHandler);
        console.error("Iframe failed to respond to ping messages");
        return;
      } else {
        console.log("Sending ping attempt:", pingAttempts);
      }

      passDataToPatientViewIframe({ type: "ping" });
      pingAttempts++;
    }, pingInterval);
  }

  function iframeInitialized() {
    console.log("Passing to iframe isPatientAudioMuted", isPatientAudioMuted);
    passDataToPatientViewIframe({
      type: "isPatientAudioMuted",
      muteValue: isPatientAudioMuted === true,
    });

    console.log("Passing to iframe isLocalAudioMuted", isLocalAudioMuted);
    passDataToPatientViewIframe({
      type: "isLocalAudioMuted",
      muteValue: isLocalAudioMuted === true,
    });
  }

  useEffect(() => {
    console.log("Inside isLocalAudioMuted", isLocalAudioMuted);
    passDataToPatientViewIframe({
      type: "isLocalAudioMuted",
      muteValue: isLocalAudioMuted === true,
    });
  }, [isLocalAudioMuted]);

  useEffect(() => {
    console.log("Inside isPatientAudioMuted", isPatientAudioMuted);
    passDataToPatientViewIframe({
      type: "isPatientAudioMuted",
      muteValue: isPatientAudioMuted === true,
    });
  }, [isPatientAudioMuted]);

  useEffect(() => {
    console.log("Inside peerVideoStream", peerVideoStream);

    if (peerVideoStream) {
      peerVideoStream.getTracks().forEach((track) => {
        console.log("Track:", track);
        console.log("Track Type:", track.kind);
      });

      if (videoRef.current) {
        videoRef.current.srcObject = peerVideoStream;
      } else {
        console.log("Video Ref is null");
      }
    } else {
      console.log("Peer Video Stream is null");
    }
  }, [peerVideoStream]);

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsVisible(true);
    });
  }, []);

  const memoizedContent = useMemo(() => {
    return (
      <div
        id={`div-id-${peer_id}`}
        className={styles.videoDiv}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "12px",
          overflow: "hidden",
          position: "relative",
          backgroundColor: isPatient ? "white" : null,
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? "scale(1)" : "scale(0.8)",
          backgroundColor: isPatient ? "white" : null,
          transition: "opacity 1.5s ease-in-out, transform 1.5s ease-in-out",
        }}
      >
        {isPatient && patientViewUrl ? (
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <iframe
              id="patient-view-iframe"
              ref={iframeRef}
              src={patientViewUrl}
              allow="microphone"
              frameBorder="0"
              width="100%"
              height="100%"
              style={{
                position: "relative",
              }}
              onLoad={() => {
                console.log("Iframe loaded :" + patientViewUrl);
                setIsVisible(true);
                rebalanceChildren();
                passInitialDataToPatientViewIframe();
              }}
            />
            {isPatient && isPatientVideoMuted && (
              <div
                id="patient-view-overlay-div"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#555558",
                  opacity: 1,
                  zIndex: 1000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontFamily: "MetropolisMedium",
                    fontWeight: 600,
                    fontSize: "3rem",
                    lineHeight: "72px",
                    letterSpacing: "0%",
                    textAlign: "center",
                    color: "#FFFFFF",
                    backgroundColor: randomColor,
                    borderRadius: "50%",
                    width: "30%",
                    maxWidth: "160px",
                    aspectRatio: "1 / 1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {Utilities.getInitials(displayName)}
                </span>
              </div>
            )}
          </div>
        ) : (
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <video
              id={`video-id-${peer_id}`}
              autoPlay
              ref={videoRef}
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                borderRadius: "12px",
              }}
            />
            {peerVideoMuted && !isScreenBeingShared && (
              <div
                id={`video-overlay-div-${peer_id}`}
                className={styles.videoOverlayDiv}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#555558",
                  opacity: 1,
                  zIndex: 1000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontFamily: "MetropolisMedium",
                    fontWeight: 600,
                    fontSize: "3rem",
                    lineHeight: "72px",
                    letterSpacing: "0%",
                    textAlign: "center",
                    color: "#FFFFFF",
                    backgroundColor: randomColor,
                    borderRadius: "50%",
                    width: "30%",
                    maxWidth: "160px",
                    aspectRatio: "1 / 1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {Utilities.getInitials(displayName)}
                </span>
              </div>
            )}
          </div>
        )}
        <div
          className={styles.nameBox}
          id={"name-id-" + peer_id}
          style={{ zIndex: 2000 }}
        >
          {displayName}
        </div>
        {(isPatient && isPatientAudioMuted) ||
          (!isPatient && peerAudioMuted) ? (
            <div
              id={`mute-indicator-div-${peer_id}`}
              className={styles.muteIndicatorDiv}
            >
              <img src="/assets/mic-off-small.svg" />
            </div>
          ) : null}
        {joinedPeerListCount > 1 && 
        <div className={styles.pinBox} onClick={rightButtonHandler}>
           <img src={rightButtonText === "Pin"? "/assets/icn-unpin.svg":"/assets/icn-pin.svg"} />
          {rightButtonText}
         
        </div>
        }
      </div>
    );
  }, [
    peer_id,
    displayName,
    isVisible,
    isPatient,
    patientViewUrl,
    isPatientVideoMuted,
    peerVideoStream,
    peerAudioMuted,
    peerVideoMuted,
    joinedPeerListCount,
    randomColor,
    rightButtonText,
    isScreenBeingShared,
  ]);

  return memoizedContent;
};

export default VideoDiv;
