import React, { useState, useRef, useEffect } from "react";
import Button from "../Buttons";
import styles from "./style.module.scss";

const CustomTooltip = ({ children, visible, position }) => {
  if (!visible) return null;

  return (
    <div style={{ position: "relative" }}>
      <div className={styles.arrowStyle}></div>
      <div className={styles.tooltipStyle} style={{
        top: position.top,
        left: position.left,
      }}>{children}</div>
    </div>
  );
};

const OptionsButtonLobby = ({
  removePeer,
  mutePeer,
  peerId,
  isAdmin,
  participantStates,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const buttonRef = useRef(null);

  const handleOptionsClick = () => {
    setShowTooltip(!showTooltip);
  };

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const tooltipPosition = {
    top: "8px",
    left: "-120px",
  };

  return (
    <div style={{ position: "relative" }} ref={buttonRef}>
      <Button.IconButtonWithHoverColor
        icon={"/assets/icn-lobby-options.svg"}
        imageHeight={24}
        imageWidth={24}
        style={{
          cursor: "pointer",
          color: "#FFFFFF",
          border: "1px solid #DADEE1",
          height: 40,
          width: 48,
          marginLeft: 5,
          marginRight: 5,
          padding: "4px 12px",
        }}
        onClickHandler={handleOptionsClick}
      />
      <CustomTooltip visible={showTooltip} position={tooltipPosition}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <button
            className={styles.tooltipButton}
            onClick={() => {
              if (isAdmin) {
                mutePeer(
                  peerId,
                  participantStates[peerId].isPatient,
                  participantStates[peerId].audioMuted,
                  !participantStates[peerId].videoMuted,
                  participantStates[peerId].isScreenBeingShared
                );
              }
            }}
          >
            <img
              src={
                participantStates[peerId]?.videoMuted
                  ? "/assets/icn-lobby-start-watching.svg"
                  : "assets/icn-lobby-stop-watching.svg"
              }
            />
            <span className={styles.tooltipButtonText}>
              {participantStates[peerId]?.videoMuted
                ? "Start Watching"
                : "Stop Watching"}
            </span>
          </button>
          <button
            className={styles.tooltipButton}
            onClick={() => removePeer(peerId)}
          >
            <img src="/assets/icn-remove-from-call.svg" />
            <span className={styles.tooltipButtonText}>Remove from call</span>
          </button>
        </div>
      </CustomTooltip>
    </div>
  );
};

export default OptionsButtonLobby;
