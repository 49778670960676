import Button from "../Buttons";
import AdmitAllowedLobbyMemberPopUp from "../AdmitAllowedLobbyMemberPopUp";
import styles from "./style.module.scss";
import { useEffect } from "react";
import {
  TOOLTIP_POSITION,
  ToolTipWrapper,
} from "@lana-health/lana-react-component-library";

export default function ControlButton({
  askToJoinLobby,
  joinedPeerListCount,
  withLobby,
  peerSharingScreenId,
  withElipsis,
  isSomeOneElseRecording,
  withScreenShareButton,
  setShowSelectDevicePopUp,
  setAdmitAllowedLobbyMember,
  isRecording,
  startRecording,
  stopRecording,
  lobbyActive,
  isLocalAudioMuted,
  setIsLocalAudioMuted,
  isLocalVideoMuted,
  setIsLocalVideoMuted,
  setLobbyActive,
  isSharingScreen,
  toggleScreenShare,
  controlButtonsPaddingLeftStyle,
}) {
  useEffect(() => {
    if (isSharingScreen) {
      setIsLocalVideoMuted(false);
    }
  }, [isSharingScreen]);
  return (
    <div
      style={{ paddingLeft: controlButtonsPaddingLeftStyle }}
      className={styles.ctrlbttns}
    >
      <ToolTipWrapper
        infoText={isLocalAudioMuted ? "Unmute" : "Mute"}
        style={{ transform: "translateX(0px) translateY(-100px)" }}
        tooltipPosition={TOOLTIP_POSITION.BOTTOM}
      >
        <Button.IconButton
          icon={
            isLocalAudioMuted
              ? "/assets/icn-microphone-off.svg"
              : "/assets/icn-microphone-on.svg"
          }
          style={isLocalAudioMuted ? { backgroundColor: "#F95763" } : {}}
          onClickHandler={() => {
            setIsLocalAudioMuted(!isLocalAudioMuted);
          }}
        />
      </ToolTipWrapper>
      <ToolTipWrapper
        infoText={isLocalVideoMuted ? "Turn camera on" : "Turn camera off"}
        tooltipPosition={TOOLTIP_POSITION.BOTTOM}
        style={{ transform: "translateX(-30px) translateY(-100px)" }}
      >
        <Button.IconButton
          icon={
            isLocalVideoMuted
              ? "/assets/camera-icn-off.svg"
              : "/assets/camera-icon-on.svg"
          }
          style={
            isLocalVideoMuted
              ? { backgroundColor: "#F95763" }
              : isSharingScreen
              ? {
                  opacity: "0.4",
                  cursor: "not-allowed",
                  pointerEvents: "none",
                }
              : {}
          }
          onClickHandler={() => {
            setIsLocalVideoMuted(!isLocalVideoMuted);
          }}
          disabled={isSharingScreen}
        />
      </ToolTipWrapper>

      {withScreenShareButton && (
        <ToolTipWrapper
          infoText={
            isSharingScreen
              ? "Stop Sharing"
              : joinedPeerListCount === 0 || peerSharingScreenId
              ? "Someone is already sharing their screen"
              : "Share Screen"
          }
          tooltipPosition={TOOLTIP_POSITION.BOTTOM}
          style={{ transform: "translateX(-25px) translateY(-100px)" }}
        >
          <Button.IconButton
            icon={"/assets/icn-share-screen.svg"}
            style={
              isSharingScreen
                ? { backgroundColor: "#5D9EEE", cursor: "pointer" }
                : joinedPeerListCount === 0 || peerSharingScreenId
                ? {
                    opacity: "0.4",
                    cursor: "not-allowed",
                    pointerEvents: "none",
                  }
                : { backgroundColor: "#5F5F63", cursor: "pointer" }
            }
            onClickHandler={() => {
              toggleScreenShare();
            }}
          />
        </ToolTipWrapper>
      )}
      {withLobby && (
        <AdmitAllowedLobbyMemberPopUp
          setAdmitAllowedLobbyMember={setAdmitAllowedLobbyMember}
          askToJoinLobby={askToJoinLobby}
          setLobbyActive={setLobbyActive}
        >
          <ToolTipWrapper
            infoText={"Lobby"}
            tooltipPosition={TOOLTIP_POSITION.BOTTOM}
            style={{ transform: "translateX(0px) translateY(-100px)" }}
          >
            <Button.IconButton
              icon={"/assets/icn-people.svg"}
              frequency={joinedPeerListCount + 1}
              style={{
                backgroundColor: "#555558",
                gap: 4,
              }}
              onClickHandler={() => setLobbyActive(!lobbyActive)}
            />
          </ToolTipWrapper>
        </AdmitAllowedLobbyMemberPopUp>
      )}
      <ToolTipWrapper
        infoText={"Options"}
        tooltipPosition={TOOLTIP_POSITION.BOTTOM}
        style={{ transform: "translateX(0px) translateY(-100px)" }}
      >
        <Button.IconButton
          icon={"/assets/icn-more.svg"}
          style={{ backgroundColor: "#555558" }}
          onClickHandler={() => {
            setShowSelectDevicePopUp(true);
          }}
        />
      </ToolTipWrapper>
    </div>
  );
}
