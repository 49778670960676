import { useState } from "react";
import styles from "./style.module.scss";
import { CALL_END_TYPES } from "./CALL_END_TYPES";

export default function CallEndedScreen({ totalCallTime , callEndType }) {

  const convertedTime = totalCallTime.replace(":", "m ") + "s";
  const handleRefreshClick = () => {
    window.location.reload();
  };
  
  return (
    <div>
      <div className={styles.wrapperDiv}>
        <img className={styles.logoImg} src="/logo-2.png" />

        <img
          className={styles.profileImg}
          src="/assets/icn-you-left.png"
        />

        <div className={styles.callEndedTitle}>
        
        {callEndType === CALL_END_TYPES.PATIENT_DECLINED ? "Patient declined to answer": callEndType === CALL_END_TYPES.ADMIN_REJECTED ? "Your request to join the call was declined" : "You’ve left the session"}
         {callEndType === CALL_END_TYPES.STANDARD && <p className={styles.timeParagraph}>Call minutes : {convertedTime}</p>} 
        </div>

        <div className={styles.callEndedSubtitle}>
          You can close this window
        </div>
        <div
          className={styles.callEndedSubtitle}
          style={{ fontSize: "16px", marginTop: "7rem", display: "flex" }}
        >
          
          {callEndType === CALL_END_TYPES.ADMIN_REJECTED ? "Request again? " : callEndType === CALL_END_TYPES.STANDARD ? "Left accidentally? " : null}

          {callEndType !== CALL_END_TYPES.PATIENT_DECLINED && 
          <span
            style={{
              color: "#8549FF",
              marginLeft: "0.5rem",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={handleRefreshClick}
          >
            {/* Rejoin Session{" "} */}
            {callEndType === CALL_END_TYPES.ADMIN_REJECTED ? "Request " : "Rejoin Session "}

            <div style={{ position: "relative", bottom: "2px" }}>
              <img src="/assets/icn-arrow-right.svg" />
            </div>
          </span>}
        </div>
      </div>
    </div>
  );
}

