import { useEffect, useState } from "react";
import "./App.css";
import { useCookies } from "react-cookie";
import LanaWebrtcApp from "./LanaWebrtcApp";
import ZoomApp from "./ZoomApp";
import UsbCameraMonitoringWrapperView from "./Views/UsbCameraMonitoringView/UsbCameraMonitoringWrapperView";

function App() {
  const searchParams = new URLSearchParams(document.location.search);

  const [cookies, setCookie] = useCookies(["token"]);

  const [token, setToken] = useState(
    searchParams.get("token") || cookies.token
  );

  console.log("searchParams", searchParams);

  useEffect(() => {
    setCookie("token", token, { path: "/" });
    const currentUrl = window.location.href;
    const newUrl = currentUrl.replace(/&token=[^&]*/, "");
    window.history.replaceState({}, document.title, newUrl);
  }, [token]);

  if (searchParams.get("callType") === "INTERNAL_WEBRTC") {
    return <LanaWebrtcApp token={token} setToken={setToken} />
  } else if (searchParams.get("callType") === "EXTERNAL_ZOOM") {
    return (
      <ZoomApp token={token} setToken={setToken} />
    );
  } else if (searchParams.get("monitoringType") === "USB_CAMERA_WEBRTC") {
    return (
      <UsbCameraMonitoringWrapperView token={token} setToken={setToken} />
    )
  }
}

export default App;
